<template>
  <div  class="box">
    <h2>{{ dataType }} {{ period }}, for {{ site }} <span v-if="site == 'all'">sites</span></h2>
    <h1 class="blue" v-if="dataType == 'sales'">$ {{ current.toFixed(2) }}</h1>
    <h1 class="blue" v-else>{{ current }} units</h1>
    <h5>Last <span v-if="period == 'today'">Week</span> {{ period }}</h5>
    <h5 v-if="dataType == 'sales'">$ {{ previous.toFixed(2) }}</h5>
    <h5 v-else>{{ previous }} units</h5>
    <h5 :class="current < previous ? 'negative' : 'positive'">{{ percentage }} %</h5>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { api } from "@/helpers/api";

export default {
  name: "ReportsComponent",
  data() {
    return {
      current: 0,
      previous: 0,
      percentage: 0,
    };
  },

  props: {
    editData: {
      type: Boolean,
      required: false,
    },
    period: {
      type: String,
      required: true,
    },
    dataType: {
      type: String,
      required: true,
    },
    site: {
      type: String,
      required: true,
    },
    day: {
      type: Array,
      required: true,
    },
    otherDay: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      sites: "sites",
      reportSite: "reportSite",
      companyName: "companyName",
    }),
  },

  methods: {
    async getTotals(start, end) {
      if (this.dataType == "sales") {
        return await api.get_sales_total(this.companyName, this.site, start, end);
      } else {
        return await api.get_count_total(this.companyName, this.site, start, end);
      }
    },

    async collectData() {
      const result = await this.getTotals(this.day[0], this.day[1]);
      this.current = result[0] ? result[0].value : 0;

      const response = await this.getTotals(this.otherDay[0], this.otherDay[1]);
      this.previous = response[0] ? response[0].value : 0;
      return this.percentageTotal(this.current, this.previous);
    },

    percentageTotal(value1, value2) {
      this.percentage = parseInt((((value1 - value2) / value2) * 100).toFixed(2));
    },

    editDashItem() {
      console.log(this.editData);
      console.log("Edit Dash Item");
    },
  },

  mounted() {
    this.collectData();
    console.log(this.editData);
  },

  watch: {
    site: function() {
      this.collectData();
    },
    dataType: function() {
      this.collectData();
    },
    currentDay: function() {
      this.collectData();
    },
    editData: function() {
      this.editDashItem();
    },
  },
};
</script>

<style scoped lang="scss">
.box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 7rem;
  // width: 100%;
  border-radius: 5px;
  padding: 1rem;
  margin: 0.5rem;
  // background-color: var(--primary-colour);
  h2,
  h1,
  h3 {
    margin: 0.5rem;
    text-transform: capitalize;
  }
  h5 {
    margin: 0 0.5rem;
    text-wrap: nowrap;
  }
  .blue {
    color: var(--action-colour);
  }
  .negative {
    color: rgb(202, 45, 45);
  }
  .positive {
    color: rgb(45, 129, 45);
  }
}

@media only screen and (orientation: portrait) {
  .box {
    height: 100%;
    width: 7rem;
    font-size: smaller;
    padding: 0.5rem 0;
    margin: 0.5rem;
    h1, h2, h3 {
      margin: 0.5rem;
      font-size: 1rem;
      line-height: 1rem;
    }
  }
}
</style>
