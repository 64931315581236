<template>
  <div class="container">
    <ul class="settings-container">
      <li v-for="(value, key) in settings" :key="key" class="setting-box">
        <p>{{ formatSettingName(key) }}</p>
        <ToggleSwitch :switchId="'switch-' + key + site" :name="key" :value="value" @toggle-action="saveSetting" />
      </li>
    </ul>
  </div>
</template>

<script>
import ToggleSwitch from "@/components/_layouts/ToggleSwitch.vue";
import { mapGetters } from "vuex";
import createdMixin from "@/components/_mixins/createdMixin";

export default {
  name: "DirectSettings",
  mixins: [createdMixin],
  components: {
    ToggleSwitch,
  },
  data() {
    return {
      settings: {},
    };
  },

  emits: ["updateSettings"],

  props: {
    site: {
      type: String,
      required: true,
    },
  },

  mounted() {
    this.$store.dispatch("getSettings");
    this.filterSettings();
  },

  computed: {
    ...mapGetters({
      settingsViaSite: "settingsViaSite",
      channels: "channels",
    }),
  },

  methods: {
    filterSettings() {
      let setting = {};
      let s = this.settingsViaSite(this.site).filter((setting) => typeof setting[1] === "boolean");
      s.map((i) => (setting[i[0]] = i[1]));
      this.settings = setting;
      return Object.entries(setting);
    },

    formatSettingName(setting) {
      if (typeof setting == "string" && setting.includes("_")) {
        setting = setting.replace(/_/g, " ");
      } else if (typeof setting == "string" && setting.match(/([A-Z])/g)) {
        setting = setting.replace(/([A-Z])/g, " $1");
      }
      return setting + "?";
    },

    saveSetting(s, value) {
      this.channels.map((channel) => {
        let topic = channel.topic.split(":");
        if (topic[0] == "pos" && topic[2] == this.site) {
          console.log(this.site, s, value);
          channel.push("company:site:setting", { key: s, value: value }).receive("ok", () => {
            this.filterSettings();
            this.$emit("updateSettings");
          });
        }
      });
    },
  },
  watch: {},
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 97.5%;
  /* padding: 0 1rem; */
}

.settings-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  padding: 1rem;
  margin: 0;
  background: var(--primary-colour);
  border-radius: 20px;
  /* box-shadow: var(--box-shadow); */
  /* filter: drop-shadow(10px 20px 30px rgba(0, 0, 0, 0.2)); */
  width: 100%;
}

.setting-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 10rem;
  height: 100%;
  padding: 0.5rem 0.5rem;
  border: none;
  box-shadow: var(--box-shadow);
  /* filter: drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.2)); */
  border-radius: 10px;
  margin: 0.5rem;
  background-color: var(--secondary-colour);
  p {
    text-wrap: nowrap;
    margin: 0.5rem 0 0 0;
    text-transform: capitalize;
  }
}
@media only screen and (orientation: portrait) {
  .container {
    width: 100%;
    padding: 0;
  }
  .settings-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0;
    align-items: center;
    justify-content: center;
    width: 95%;
  }
  .setting-box {
    width: 85%;
    height: 5rem;
    margin: 0.25rem;
  }
}
</style>
