<template>
  <div class="banner">
    <Theme />
    <p>{{ companyName }}</p>
    <router-link to="/settings" ><font-awesome-icon icon="wrench"/></router-link>
  </div>
  <div class="container">
    <Menu />

    <div id="content"><slot /></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Menu from "@/components/_layouts/Menu";
import Theme from "@/components/Settings/Theme";
export default {
  components: {
    Menu,
    Theme,
  },
  name: "Authenticated",
  data() {
    return {
      showDateMenu: false,
    };
  },
  methods: {
    handleMenu() {},
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      companyName: "companyName",
    }),
  },
};
</script>

<style lang="scss">
.banner {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 0 0 1rem 5rem;
  padding: 0 0.5rem 0 0.5rem;
  z-index: 100;
  width: 100%;
  right: 0;
  background-color: var(--primary-colour);
  // filter: drop-shadow(20px 20px 30px rgba(0, 0, 0, 0.2));
  svg, p {
    padding: 0.5rem;
    color: var(--text-colour);
  }
}
.container {
  display: flex;
  top: 0;
  z-index: 1000;
  // background-color: var(--primary-colour);
  color: var(--text-colour);
  #content {
    flex: 1 0;
    margin: 3.5rem 0 0 5rem;
  }
}

@media (orientation: portrait) {
  .container {
    width: 100%;
    #content {
      margin-left: 0;

      // margin-bottom: 5rem;
    }
  }
}
</style>
