<template>
  <Authenticated>
    <div class="web-page">
      <h1>Welcome to the Web Page</h1>
      <p v-for="chan in webChannels" :key="chan">{{ chan }}</p>
      {{ channels.forEach((chan) => chan) }}
    </div>
  </Authenticated>
</template>

<script>
import Authenticated from "../_layouts/Authenticated.vue";
import { mapGetters } from "vuex";

export default {
  name: "WebPage",
  components: {
    Authenticated,
  },
  computed: {
    ...mapGetters({
      channels: "channels",
    }),
    webChannels() {
      let topics = [];
      this.channels.map((chan) => (chan.topic.split(":")[0] == "web") ? topics.push(chan.topic) : false);
      return topics;
    },
  },
  mounted() {
    //console.log(this.channels);
  },
  data() {
    return {};
  },
  methods: {
    circularReplacer() {
      const seen = new WeakSet(); // object
      return (key, value) => {
        if (typeof value === "object" && value !== null) {
          if (seen.has(value)) {
            return;
          }
          seen.add(value);
        }
        return value;
      };
    }
  },
};
</script>

<style scoped lang="scss">
.web-page {
  text-align: center;
  margin-top: 50px;
}
</style>
