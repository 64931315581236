<template>
  <teleport to="body">
    <div v-if="showModal" class="modal">
      <div class="product">
        <div class="content">
          <font-awesome-icon
            class="exit"
            icon="xmark"
            @click.stop.prevent="closeModal"
          />

          <h3 class="title">Create/Edit modifier</h3>
          <label>Name</label>
          <input
            type="text"
            v-model="currentModifier.name"
            placeholder="Name"
          />

          <label>Active / Available Modifier</label>
          <Toggle
            class="toggle"
            onLabel="Available"
            offLabel="Unavailable"
            v-model="currentModifier.active"
          />

          <label>Description</label>
          <input type="text" v-model="currentModifier.desc" />

          <label>Quantity</label>
          <input
            type="number"
            v-model.number="currentModifier.quantity"
            placeholder="0"
          />

          <label>Modifier Price</label>
          <input
            type="number"
            v-model.number="currentModifier.price"
            placeholder="0"
          />

          <div class="allergen">
            <label for="allergen">Allergens</label>
            <AllergensList
              :allergens="currentModifier.allergens"
              @allergenChanged="currentModifier.allergens = $event"
            />
          </div>
        </div>

        <div class="actions">
          <button class="action danger" @click.prevent.stop="remove">
            Delete
          </button>
          <button
            class="action cancel cancel-text"
            @click.prevent.stop="cancel"
          >
            Cancel
          </button>
          <button class="action primary" @click.prevent.stop="commit()">
            Save
          </button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import Toggle from "@vueform/toggle";
import AllergensList from "@/components/Menus/AllergenList.vue";

export default {
  name: "ModifierModal",
  props: {
    open: { type: Boolean, required: true },
    modifier: { type: Object, required: true },
    modifierset: { type: Object, required: true },
  },
  emits: ["closeModal", "commit", "remove"],
  components: {
    Toggle,
    AllergensList,
  },
  data() {
    return {
      showModal: false,
    };
  },

  computed: {
    currentModifier() {
      return this.modifier;
    },
  },

  methods: {
    closeModal() {
      this.showModal = !this.showModal;
      this.currentModifier = {
        active: false,
        desc: null,
        name: null,
        price: null,
        quantity: 0,
      };
      this.$emit("closeModal");
    },

    remove() {
      this.showModal = !this.showModal;
      // this.$emit('remove', this.currentModifier);
    },

    commit() {
      this.editedModifier = this.currentModifier;
      this.showModal = !this.showModal;
      this.$emit("commit");
    },

    cancel() {
      this.showModal = !this.showModal;
    },
  },
  watch: {
    open() {
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
input {
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.mod-select {
  h3 {
    margin: 0;
  }
  .set {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    width: 80vw;
    //remove scrollbar
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
    .mods-in-set {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
      margin: 0.5rem;
      padding: 0.5rem;
      border-radius: 10px;
      background-color: var(--primary-colour);
      box-shadow: var(--box-shadow);
      cursor: pointer;
      p {
        text-wrap: wrap;
        width: 7rem;
      }
      &:active {
        box-shadow: none;
      }
    }
  }
}

</style>
