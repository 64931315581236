<template>
  <div class="chart-container">
    <VChart v-if="chartType == 'line'" :option="lineChartOptions" class="chart" autoresize></VChart>
    <VChart v-if="chartType == 'bar'" :option="barChartOptions" class="chart" autoresize></VChart>
  </div>
</template>

<script>
import * as echarts from "echarts/core";
import { GridComponent, TitleComponent, LegendComponent, TooltipComponent } from "echarts/components";
import { LineChart, BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import VChart from "vue-echarts";
import { api } from "@/helpers/api";
import { dayRange } from "@/helpers/time";

echarts.use([CanvasRenderer, LineChart, BarChart, GridComponent, LegendComponent, TitleComponent, TooltipComponent]);

export default {
  name: "App",
  components: {
    VChart,
  },
  data() {
    return {
      labels: [],
      dataSet1: [],
      dataSet2: [],
      cats: ["Today", "Last Week"],
      yName: "",
      xName: "",
      current: 0,
      previous: 0,
      company: localStorage.getItem("company"),
      dark: true,
      finalDay: [],
    };
  },

  props: {
    site: {
      type: String,
      required: true,
    },
    dataType: {
      type: String,
      required: true,
    },
    day: {
      type: Array,
      required: true,
    },
    otherDay: {
      type: Array,
      required: true,
    },
    period: {
      type: String,
      required: true,
    },
    chartType: {
      type: String,
      required: true,
    },
  },

  methods: {
    utcFix(date) {
      const hour = parseInt(date.slice(11, 13));
      const day = date.slice(0, 10);
      const timezoneOffset = new Date(day).getTimezoneOffset() / 60;
      return (hour - timezoneOffset) % 24;
    },

    labelDay(zero = false) {
      const hoursInDay = 24;
      const hours = [];

      for (let i = 0; i < hoursInDay; i++) {
        zero ? hours.push(0) : hours.push(i);
      }
      return hours;
    },

    labelWeek() {
      const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
      return days;
    },

    padDay(data) {
      const hours = this.labelDay(true);
      const adj = data.map((i) => ({ hour: this.utcFix(i.key[0]), sales: i.value }));

      adj.map((row) => (hours[row.hour] = row.sales));

      let i = this.getDaySales(hours);
      this.finalDay = this.labelDay().slice(i[0], i[1]);

      return hours.slice(i[0], i[1]);
    },

    getDaySales(hours) {
      const noneZero = (h) => h != 0;
      return [hours.findIndex(noneZero) - 1, hours.findLastIndex(noneZero) + 2];
    },

    async getTimeframe(days) {
      if (this.dataType == "sales") {
        return await api.get_sales_timeframe(this.company, this.site, days);
      } else {
        return await api.get_count_timeframe(this.company, this.site, days);
      }
    },

    async getTotals(start, end) {
      if (this.dataType == "sales") {
        return await api.get_sales_total(this.company, this.site, start, end);
      } else {
        return await api.get_count_total(this.company, this.site, start, end);
      }
    },

    async getData(start, end) {
      if (this.dataType == "sales") {
        return await api.get_sales_data(this.company, this.site, start, end);
      } else {
        return await api.get_product_count(this.company, this.site, start, end);
      }
    },

    fixDateQuery(unix) {
      return new Date(unix).toISOString().slice(0, 13);
    },

    async collectData() {
      if (this.period == "day") {
        var salesData = await this.getData(this.day[0], this.day[1]);
        this.dataSet1 = this.padDay(salesData);

        salesData = await this.getData(this.otherDay[0], this.otherDay[1]);
        this.dataSet2 = this.padDay(salesData);

        this.labels = this.finalDay;
      } else if (this.period == "week") {
        let week = [];
        let lastWeek = [];
        var curr_day = Date.parse(this.day[0] + ":00:00.000Z");
        var last_day = Date.parse(this.otherDay[0] + ":00:00.000Z");

        for (var day in this.labelWeek()) {
          var dayStart = curr_day;
          var lastDayStart = last_day;

          curr_day += 1000 * 60 * 60 * 24;
          last_day += 1000 * 60 * 60 * 24;

          week.push([this.fixDateQuery(dayStart), this.fixDateQuery(curr_day)]);
          lastWeek.push([this.fixDateQuery(lastDayStart), this.fixDateQuery(last_day)]);
          this.labels.push(day);
        }
        this.dataSet1 = await this.getTimeframe(week);
        this.dataSet2 = await this.getTimeframe(lastWeek);
        this.labels = this.labelWeek();
      } else if (this.period == "month") {
        let month = [];
        let lastMonth = [];
        let monthLabels = [];
        curr_day = Date.parse(this.day[0] + ":00:00.000Z");
        last_day = Date.parse(this.otherDay[0] + ":00:00.000Z");

        for (var i = 1; i <= dayRange.getMonthDays(); i++) {
          dayStart = curr_day;
          lastDayStart = last_day;

          curr_day += 1000 * 60 * 60 * 24;
          last_day += 1000 * 60 * 60 * 24;

          month.push([this.fixDateQuery(dayStart), this.fixDateQuery(curr_day)]);
          lastMonth.push([this.fixDateQuery(lastDayStart), this.fixDateQuery(last_day)]);
          monthLabels.push(i);
        }
        this.dataSet1 = await this.getTimeframe(month);
        this.dataSet2 = [];
        this.labels = monthLabels;
      }
    },
  },

  mounted() {
    this.collectData();
  },

  watch: {
    period: function() {
      this.collectData();
    },
    site: function() {
      this.collectData();
    },
    dataType: function() {
      this.collectData();
    },
  },

  computed: {
    barChartOptions() {
      let style = getComputedStyle(document.body);
      return {
        xAxis: {
          type: "category",
          data: this.labels,
          name: this.xName,
          nameLocation: "middle",
          nameGap: 30,
          nameTextStyle: {
            color: "#3bafda",
            fontSize: 16,
          },
        },
        yAxis: {
          type: "value",
          name: this.yName,
          nameLocation: "end",
          nameGap: 30,
          show: true,
          nameTextStyle: {
            color: "#3bafda",
            fontSize: 16,
            width: "100%",
          },
        },
        series: [
          {
            data: this.dataSet1,
            type: "bar",
            name: this.cats[0],
            itemStyle: {
              color: "#3bafda",
            },
          },
          {
            data: this.dataSet2,
            type: "bar",
            name: this.cats[1],
            itemStyle: {
              color: style.getPropertyValue("--text-colour"),
            },
          },
        ],
        tooltip: {
          trigger: "axis",
        },
        legend: {
          textStyle: {
            color: "#3bafda",
          },
          data: [this.cats[0], this.cats[1]],
        },
      };
    },
    lineChartOptions() {
      let style = getComputedStyle(document.body);
      return {
        xAxis: {
          name: this.xName,
          type: "category",
          data: this.labels,
          nameLocation: "middle",
          nameGap: 30,
          nameTextStyle: {
            color: "#3bafda",
            fontSize: 16,
          },
        },
        yAxis: {
          type: "value",
          name: this.yName,
          nameLocation: "end",
          nameGap: 30,
          show: true,
          nameTextStyle: {
            color: "#3bafda",
            fontSize: 16,
            width: "100%",
          },
        },
        series: [
          {
            name: this.cats[0],
            data: this.dataSet1,
            type: "line",
            smooth: true,
            itemStyle: {
              color: "#39AAD4",
            },
            lineStyle: {
              color: "#39AAD4",
            },
            areaStyle: {
              color: "#39AAD430",
            },
          },
          {
            name: this.cats[1],
            data: this.dataSet2,
            type: "line",
            smooth: true,
            itemStyle: {
              color: style.getPropertyValue("--text-colour"),
            },
            lineStyle: {
              color: style.getPropertyValue("--text-colour"),
            },
            areaStyle: {
              color: style.getPropertyValue("--text-colour"),
            },
          },
        ],
        tooltip: {
          trigger: "axis",
        },
        legend: {
          textStyle: {
            color: "#3bafda",
          },
          data: [this.cats[0], this.cats[1]],
        },
      };
    },
  },
};
</script>

<style scoped lang="scss">
.chart-container {
  height: 60vh;
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-colour);
}
@media only screen and (orientation: portrait) {
  .chart-container {
    width: 100vw;
    padding: 2.55px;
  }
}
</style>
