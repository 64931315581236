<template>
  <Authenticated>
    <section class="header">
      <h1>Kitchen Display</h1>
      <p>Interact with your Sites KDS units</p>
    </section>
    <section v-for="site in siteMap" :key="site" class="main">
      <h2 v-if="site.stations.kds != 0">{{ site.site }}</h2>
      <div class="unit-container">
        <div v-for="kds in site.stations.kds" :key="kds" class="unit" @click.stop.prevent="openKDSModal(kds)">
          <h4>{{ kds.name }}</h4>
          <p>Tracked Categories:</p>
          <ul class="list">
            <li v-for="category in kds.categories" :key="category" class="list-item">
              {{ category }}
            </li>
          </ul>
        </div>
      </div>
    </section>
    <KDSStationModal :open="showKDSModal" :station="KDSStation" :newStation="newStation" @update="updateStations" @close="close" />
  </Authenticated>
</template>

<script>
import Authenticated from "../_layouts/Authenticated.vue";
import KDSStationModal from "../Settings/KDSStationModal.vue";
import { api } from "@/helpers/api";
import { mapGetters } from "vuex";
export default {
  name: "KDSPage",
  components: {
    Authenticated,
    KDSStationModal,
  },
  data() {
    return {
      showKDSModal: false,
      newStation: false,
      KDSStation: {},
    };
  },
  methods: {
    close() {
      this.showKDSModal = false;
    },
    openKDSModal(incoming, newStation = false) {
      console.log('click');
      this.KDSStation = {
        name: null,
        site: null,
        categories: [],
      };
      this.newStation = newStation;
      if (newStation) {
        this.KDSStation.site = incoming;
        this.showKDSModal = !this.showKDSModal;
        return;
      }
      this.KDSStation = incoming;
      this.showKDSModal = !this.showKDSModal;
    },

    async updateStations(typeOfUpdate, typeOfStation, incomingStation) {
      let newStations = [];

      this.siteMap.forEach((site) => {
        if (site.site == incomingStation.site) {
          site.stations[typeOfStation].forEach((station) => {
            if (typeOfUpdate === "update") {
              if (station.name == incomingStation.name) {
                newStations.push(incomingStation);
              } else {
                newStations.push(station);
              }
            } else if (typeOfUpdate === "delete") {
              if (station.name != incomingStation.name) {
                newStations.push(station);
              }
            }
          });
        } else {
          site.stations[typeOfStation].forEach((station) => {
            newStations.push(station);
          });
        }
      });
      this.showKDSModal = false;
      this.showPOSModal = false;

      await api.post("/api/company/update_stations/" + this.$store.getters.companyName, {
        doc: newStations,
        station_type: `get_${typeOfStation}`,
      });
      this.$store.dispatch("initialSiteMap");
    },
  },
  computed: {
    ...mapGetters({
      channels: "channels",
      siteMap: "siteMap",
      siteSettings: "siteSettings",
      companySettings: "companySettings",
      timezone: "timezone",
      sites: "sites",
    }),
  },
  mounted() {
    // Lifecycle hook when component is mounted
  },
};
</script>

<style lang="scss" scoped>
@import "public/stylesheets/layout";
@import "public/stylesheets/wrapper";
@import "public/stylesheets/actions";
.header {
  margin: 3rem;
}
.main {
  background-color: var(--primary-colour);
  padding: 1rem 2rem;
  margin: 2rem;
  border-radius: 10px;
  perspective: 100px;
  box-shadow: var(--box-shadow);
  h2 {
    text-transform: capitalize;
  }
  .unit-container {
    display: flex;
    flex-wrap: wrap;
    .unit {
      margin: 0.5rem;
      padding: 1rem;
      width: 15rem;
      height: 11rem;
      box-shadow: var(--box-shadow);
      background-color: var(--secondary-colour);
      border-radius: 10px;
      cursor: pointer;
      perspective: 500px;
      h4,
      p {
        margin: 0.5rem 0;
        text-transform: capitalize;
      }
      .list {
        margin: 0.25rem;
        .list-item {
          list-style: none;
          color: var(--action-colour);
        }
      }
      &:active {
        box-shadow: none;
        perspective: 100px;
        translate: transform3d(0, 0, 0);
      }
    }
  }
}
@media only screen and (orientation: portrait) {
  .main {
    margin: 1rem;
    .unit-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .unit {
        width: 16rem;
      }
    }
  }
}
</style>
