class dayRanges {
  formatDate(d) {
    var day = new Date(d);
    return day.toISOString().substring(0, 13);
  }
  startTime(d) {
    var day = new Date(d);
    return new Date(day.setHours(0, 0, 0));
  }
  endTime(d) {
    var day = new Date(d);
    return new Date(day.setHours(24, 0, 0));
  }
  startOfWeek(date) {
    var d = new Date(date);
    var day = d.getDay() || 7;
    return new Date(this.startTime(this.editDay(-1 * (day - 1), date), date));
  }
  endOfWeek(d) {
    var date = new Date(d);
    var day = d.getDay() || 7;
    return new Date(this.endTime(this.editDay(7 - day, date), date));
  }
  startOfMonth(date) {
    var d = new Date(date);
    return this.startTime(new Date(d.getFullYear(), d.getMonth(), 1));
  }
  endOfMonth(date) {
    var d = new Date(date);
    return new Date(this.startTime(new Date(d.getFullYear(), d.getMonth() + 1, 0)));
  }
  editDay(days, res = new Date()) {
    res = new Date(res);
    return new Date(res.setDate(res.getDate() + days));
  }
  editWeek(weeks, res = new Date()) {
    res = new Date(res);
    return new Date(res.setDate(res.getDate() + 7 * weeks));
  }
  editMonth(months, res = new Date()) {
    res = new Date(res);
    return new Date(res.setMonth(res.getMonth() + months));
  }
  getToday() {
    return [this.formatDate(this.startTime(new Date())), this.formatDate(this.endTime(new Date()))];
  }
  getYesterday() {
    const yesterday = new Date(this.editDay(-1));
    return [this.formatDate(this.startTime(yesterday)), this.formatDate(this.endTime(yesterday))];
  }
  getLastWeekDay() {
    const day = new Date(this.editWeek(-1));
    return [this.formatDate(this.startTime(day)), this.formatDate(this.endTime(day))];
  }
  getThisWeek() {
    return [this.formatDate(this.startOfWeek(new Date())), this.formatDate(this.endOfWeek(new Date()))];
  }
  getLastWeek() {
    return [this.formatDate(this.startOfWeek(this.editWeek(-1))), this.formatDate(this.endOfWeek(this.editWeek(-1)))];
  }
  getThisMonth() {
    return [this.formatDate(this.startOfMonth(new Date())), this.formatDate(this.endOfMonth(new Date()))];
  }
  getLastMonth() {
    return [this.formatDate(this.startOfMonth(this.editMonth(-1))), this.formatDate(this.endOfMonth(this.editMonth(-1)))];
  }
  getMonthDays() {
    return new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
  }
}

// const dRange = new dayRanges();
// console.log(dRange.getYesterday());
// console.log(dRange.getToday());
// console.log(dRange.getLastWeek());
// console.log(dRange.getThisWeek());
// console.log(dRange.startOfWeek(dRange.editWeek(-3)));
// console.log(dRange.editWeek(-3));
// console.log(dRange.editWeek(-1));

const dayRange = new dayRanges();
export { dayRange };
