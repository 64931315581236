<template>
  <teleport to="body">
    <div class="modal" v-if="showModal" @click.self.prevent="showModal != showModal">
      <div class="wrapper">
        <font-awesome-icon icon="times" class="exit" @click="showModal = false" size="small" />
        <h2>Payment Options</h2>
        <section class="pay-options">
          <div class="alt-pay">
            <div class="subtitle">
              <h3>Pay with Credit/Debit Card</h3>
              <p>Continue with form to set up a new payment method.</p>
              <p>
                Pay with your credit/debit card using our secure payment gateway, powered by
                <a
                  target="_blank"
                  href="https://stripe.com/nz/pricing?utm_campaign=APAC_APAC_NZ_EN_Search_Brand_Core_EXA-PHR-2024.06_LP-SEM-Ungated-21593474153&utm_medium=cpc&utm_source=google&ad_content=703668270150&utm_term=stripe&utm_matchtype=e&utm_adposition=&utm_device=c&gad_source=1&gclid=Cj0KCQjwrKu2BhDkARIsAD7GBot_8qJEi8xC3yyRy_OWneONr_JjH0uiB4_UY8VrA2Ev7OT_k3P-cO4aAti8EALw_wcB"
                >
                  Stripe </a
                >.
              </p>
              <i>3% fee will be charged on each transaction.</i>
            </div>
          </div>
        </section>
      </div>
    </div>
  </teleport>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PaymentMethodModal",
  components: {},
  data() {
    return {
      showModal: false,
    };
  },
  props: ["open"],
  methods: {
    openBankDetailsModal() {
      this.showBankDetailsModal = !this.showBankDetailsModal;
    },
  },
  watch: {
    async open() {
      this.showModal = true;
    },
  },

  computed: {
    ...mapGetters({
      companyName: "companyName",
    }),
  },
};
</script>

<style scoped lang="scss">
.modal {
  margin: 0;

  .wrapper {
    width: 65%;
  }

  .press {
    cursor: pointer;
    &:active {
      scale: 0.99;
    }
  }

  .alt-pay {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    margin: 0.5rem;
    .subtitle {
      box-shadow: var(--box-shadow);
      border-radius: 5px;
      padding: 1rem;
      width: 25rem;
      h3,
      p,
      i {
        margin: 0.5rem 0;
      }
    }
  }

  h3,
  h2,
  p {
    color: var(--text-colour);
    margin: 1rem 0;
  }

  a {
    color: var(--action-colour);
    text-decoration: underline;
  }

  i {
    color: var(--action-colour);
    margin: 0.5rem 0;
  }

  .pay-options {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
}
@media only screen and (orientation: portrait) {
  .modal {
    .wrapper {
      width: 90%;
      .pay-options {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .alt-pay {
          .subtitle {
            width: 90%;
            p {
              margin: 0.25rem;
            }
          }
        }
      }
    }
  }
}
</style>
