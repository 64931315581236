import { Socket } from "phoenix";
import store from '@/main.js';

class BagelSocket {
  setUpChannel(_this) {
    const token = localStorage.getItem("token");
    const sites = localStorage.getItem("sites");
    const company = localStorage.getItem("company");
    const station = "admin" + token + ":" + token;
    const kdsBackendUrl = "wss://" + process.env.VUE_APP_API_HOST + "/socket";
    // const presence = new Presence(_this.kdsSations);

    //console.log(sites)
    //_this.channels = this.$.appContext.config.globalProperties.channels;
    //console.log(store.getters);
    _this.socket = store.getters.socket;
    //_this.channels = store.getters.channels;
    let channels = false;
  
    if (!_this.socket) {
      let socket = new Socket(kdsBackendUrl, {
        params: { guardian_token: token, station: station },
      });
      
      //_this.$.appContext.config.globalProperties.socket = _this.socket;
      store.dispatch("socketStore", socket);
      console.log("Attempt to connect to socket with authentication");

      if (token) {
        console.log("Ready to connect socket....");

        socket.connect();

        // if (_this.channel)
        //     _this.channel.leave();
        JSON.parse(sites).forEach((site) => {
          socket.channel("pos:" + company + ":" + site, {});
          socket.channel("kds:" + company + ":" + site, {});
          // socket.channel("web:" + company + ":" + site, {});          
        });

        // _this.channel = _this.socket.channel("pos:" + company + ":" + site, {})
        // _this.$.appContext.config.globalProperties.channel = _this.channel;
        //store.dispatch("updateChannels", channels);
        console.log(channels);

        socket.channels.forEach((channel) =>
          channel
            .join()
            .receive("ok", (resp) => {
              console.log("Joined successfully", resp);
            })
            .receive("error", (resp) => {
              console.log("Unable to join", resp);
            })
        );
      }
    }
  }
}

const bagelSocket = new BagelSocket();
export { bagelSocket };
