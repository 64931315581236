<template>
  <input type="checkbox" v-model="modelValue" :id="switchId" @change="$emit('toggleAction', name, modelValue)" />
  <label :for="switchId">Toggle</label>
</template>

<script>
export default {
  name: "ToggleSwitch",
  data() {
    return {
      modelValue: this.value,
    };
  },
  props: {
    switchId: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["toggleAction", "input", "modelValue"],
};
</script>

<style scoped>
input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

label {
  cursor: pointer;
  text-indent: -9999px;
  width: 100px;
  height: 35px;
  margin: 0 0 10px 0;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

label:after {
  content: "";
  position: absolute;
  top: 2.5px;
  left: 2.5px;
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

input:checked + label {
  background: var(--action-colour);
}

input:checked + label:after {
  left: calc(100% - 2.5px);
  transform: translateX(-100%);
}

label:active:after {
  width: 35px;
  height: 35px;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media only screen and (orientation: portrait) {
  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  input:checked + label:after {
    transform: translate(-100%);
  }

  label {
    width: 70px;
    height: 40px;
  }

  label:active:after {
    width: 40px;
    height: 40px;
  }

  label:after {
    width: 31px;
    height: 31px;
  }
}
</style>
