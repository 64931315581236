<template>
  <Authenticated>
    <div class="header">
      <h2>Settings</h2>
    </div>

    <div class="payment_options">
      <form @submit.prevent>
        <h3>Company Details</h3>
        <header class="header">
          <span>
            <input
              type="text"
              :value="companySettings.company_name"
              id="name"
              class="detail"
              placeholder="Legal Entity"
              @click.stop.prevent="this.editName = !this.editName"
            />
            <font-awesome-icon v-if="editName" icon="floppy-disk" class="edit blue" @click.stop.prevent="saveName" />
          </span>
          <span>
            <input
              type="text"
              :value="companySettings.gst"
              id="gst"
              class="detail"
              placeholder="GST number"
              @click.stop.prevent="this.editGst = !this.editGst"
            />
            <font-awesome-icon v-if="editGst" icon="floppy-disk" class="edit blue" @click.stop.prevent="saveGst" />
          </span>
        </header>

        <section class="section">
          <h3>Trading Details</h3>
          <ul class="grid wrapper">
            <li class="modifierBox">
              <label for="cogs_enable">Enable COGS:</label>
              <ToggleSwitch :switchId="'switch-' + 'cogs'" :name="'cogs_enable'" :value="this.companySettings.cogs_enable" @toggle-action="enableCogs" />
            </li>
            <li class="modifierBox">
              <label for="timeZone">Time Zone:</label>
              <div @click.stop.prevent="openTZModal">
                <p class="blue">{{ timezone }}</p>
              </div>
            </li>
            <li class="modifierBox">
              <label for="currency">Currency:</label>
              <p v-if="getCurrencySymbol" class="selected-currency blue">
                {{ getCurrencySymbol }} {{ companySettings.currency }}
              </p>
              <select @change="saveCurrency" name="currency" id="currency" class="currency">
                <option value="Select Currency" selected>Select Currency</option>
                <option v-for="(currency, idx) in currencies" :key="currency + idx">
                  {{ currency.symbol }} - {{ currency.currency }}
                </option>
              </select>
            </li>
          </ul>
        </section>

        <section class="section">
          <h3>Locations</h3>
          <ul class="grid wrapper">
            <li
              class="modifierBox"
              v-for="site in siteMap"
              :key="site"
              @click.stop.prevent="$router.push(`/settings/${site.site}`)"
            >
              <p class="site-name">{{ site.site }}</p>
            </li>
            <li class="modifierBox" @click.stop.prevent="openSiteModal">
              Add Site
            </li>
          </ul>
        </section>
      </form>
    </div>
    <TZModal :open="showTZModal" />
  </Authenticated>
</template>

<script>
// import Discounts from '@/components/Discounts/Discounts.vue'
import Authenticated from "@/components/_layouts/Authenticated";
import createdMixin from "@/components/_mixins/createdMixin";
import TZModal from "./TZModal.vue";
import { api } from "@/helpers/api";
import { mapGetters } from "vuex";
import ToggleSwitch from "@/components/_layouts/ToggleSwitch.vue";

export default {
  name: "Modifiers",
  mixins: [createdMixin],
  components: {
    Authenticated,
    TZModal,
    ToggleSwitch,
  },

  // setup: {
  //   provide("station", this.station),
  // },

  data() {
    return {
      currencies: [
        {
          currency: "AUS",
          symbol: "$",
        },
        {
          currency: "NZD",
          symbol: "$",
        },
        {
          currency: "GBP",
          symbol: "£",
        },
      ],
      savedCurrency: { currency: "", symbol: "" },
      kds_enable: false,
      pos_enable: false,
      cogs_enable: this.cogsEnabled,
      showSiteModal: false,
      showKDSModal: false,
      showPOSModal: false,
      showTZModal: false,
      showCompanionModal: false,
      POSStation: null,
      companionStation: null,
      KDSStation: null,
      newStation: false,
      settings: null,
      station: "",
      timer: null,
      editGst: false,
      editName: false,
      site: "",
      createSite: false,
      activeIndex: null,
      currentSite: {},
    };
  },

  computed: {
    ...mapGetters({
      channels: "channels",
      siteMap: "siteMap",
      siteSettings: "siteSettings",
      companySettings: "companySettings",
      timezone: "timezone",
      sites: "sites",
      cogsEnabled: "cogsEnabled",
    }),

    getCurrencySymbol() {
      if (!this.companySettings.currency) return false;
      return this.currencies.find((currency) => currency.currency == this.companySettings.currency).symbol;
    },
  },

  methods: {
    dropDown(idx) {
      this.activeIndex = this.activeIndex === idx ? null : idx;
    },

    async enableCogs() {
      this.cogs_enable = !this.cogs_enable;
      api.save_company_settings(this.companySettings.company_slug, "cogs_enable", this.cogs_enable);
      await this.$store.dispatch("getSettings");
      console.log("cogs", this.cogs_enable);
    },

    openTZModal() {
      this.showTZModal = true;
    },

    addSite() {
      api.add_site(this.site, this.companySettings.company_slug);
      this.site = "";
      this.createSite = false;
      this.$store.dispatch("getAllData");
    },

    saveGst() {
      let gst = document.getElementById("gst").value;
      api.save_company_settings(this.companySettings.company_slug, "gst", gst);
      this.editGst = !this.editGst;
      this.$store.dispatch("getSettings");
    },

    async saveCurrency() {
      let selectedCurrency = document.getElementById("currency").value;
      this.currencies.find((currency) => {
        if (currency.currency == selectedCurrency.split(" - ")[1]) {
          this.savedCurrency.currency = currency.currency;
          this.savedCurrency.symbol = currency.symbol;
        }
      });
      this.$store.dispatch("setCompanyCurrency", this.savedCurrency);
      console.log("currency from component", this.savedCurrency);
      await api
        .save_company_settings(this.companySettings.company_slug, "currency", this.savedCurrency.currency)
        .then(() => {
          this.$store.dispatch("getSettings");
        });
      // this.$store.dispatch("getSettings");
    },

    saveName() {
      let name = document.getElementById("name").value;
      api.save_company_settings(this.companySettings.company_slug, "company_name", name);
      this.editName = !this.editName;
      this.$store.dispatch("getSettings");
    },
  },
};
</script>
<style src="@vueform/toggle/themes/default.css"></style>
<style lang="scss">
@import "public/stylesheets/layout";
@import "public/stylesheets/wrapper";
@import "public/stylesheets/actions";

// .header {
//   padding: 2em 0 0 0;
// }

.site-name {
  cursor: pointer;
  text-transform: capitalize;
}

form {
  label {
    display: contents;
  }
  .blue {
    color: var(--action-colour);
    cursor: pointer;
  }

  .add {
    color: var(--action-colour);
    cursor: pointer;
    margin-left: 1rem;
  }

  .sites {
    display: flex;
    flex-direction: column;
    background: var(--background-fade);
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    margin: 1rem;
    padding: 1rem;
  }

  .add-site {
    display: flex;
    flex-direction: row;
    align-items: center;
    h3 {
      margin: 0.25rem 0 0.25rem 1rem;
    }
    span {
      display: inherit;
      margin: 0 0.5rem;
      align-items: center;
      input {
        background-color: var(--primary-colour);
        box-shadow: var(--box-shadow);
        border: 1px solid var(--action-colour);
        border-radius: 5px;
        font-size: 1.25rem;
        color: var(--text-colour);
        margin: 0 0.5rem;
        padding: 0.25rem;
      }
      button {
        background-color: var(--action-colour);
        color: var(--secondary-text-colour);
        padding: 0.5rem;
        border-radius: 5px;
        margin: 0 0.5rem;
      }
    }
  }

  .currency {
    background-color: var(--primary-colour);
    box-shadow: var(--box-shadow);
    border: 1px solid var(--action-colour);
    border-radius: 5px;
    font-size: 1rem;
    color: var(--text-colour);
    margin: 0.5rem 0.5rem;
    padding: 0.25rem;
  }

  .selected-currency {
    margin: 0.25rem;
  }

  .header {
    display: flex;
    flex-direction: column;
    background: var(--background-fade);
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    margin: 1rem;
    padding: 1rem;
    h4 {
      display: inline-block;
      margin: 1rem;
    }
    .detail {
      width: 50%;
      background-color: var(--primary-colour);
      box-shadow: var(--box-shadow);

      border: none;
      border-radius: 5px;
      font-size: 1.25rem;
      color: var(--text-colour);
      padding: 0.5rem;
      margin: 0.5rem;
    }
    .text {
      font-size: 1.25rem;
      color: var(--text-colour);
      background: none;
      border: none;
      border-radius: none;
      box-shadow: none;
      padding: 0.5rem;
      margin: 0.5rem;
    }
    .edit {
      cursor: pointer;
      margin: 0 5px;
    }
  }
}
.station {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between !important;
}

details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}

details {
  margin: 0;
  list-style: none;
  summary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .rotated {
      transform: rotate(90deg);
      transition: all 500ms ease-in-out;
    }
    div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      p {
        margin: 0.25rem 1rem;
        font-size: x-small;
        color: var(--action-colour);
      }
    }
    h3 {
      text-transform: capitalize;
      margin: 0.5rem 0;
    }
    box-shadow: var(--box-shadow);
    background-color: var(--primary-colour);
    padding: 0.75rem;
    border-radius: 5px;
    margin: 0.5rem;
    cursor: pointer;
    &:active {
      transition: all 2000ms ease-in-out;
    }
  }
}

details summary > * {
  display: inline;
}

.active {
  color: var(--action-colour) !important;
}
.discon {
  color: var(--warning-colour) !important;
}

.icon {
  font-size: 1.5rem;
}

@media only screen and (orientation: portrait) {
  div.actions {
    padding: 3rem 1rem;
  }
  .payment-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    form {
      .header {
        width: 90%;
      }
      .section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 90%;
      }
    }
  }
}
</style>
